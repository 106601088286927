import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const PolicyParagraph = memo(function PolicyParagraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 14.875rem auto 8.5rem;
  padding: 0 7.292vw;

  @media (max-width: 1199px) {
    margin: 11.25rem auto 5.625rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 9.375rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;

  > div {
    margin-top: 1.5625rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 3.75rem;

  a {
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
    text-decoration: underline;
  }
  p {
    margin-block-end: 1em;
  }
  ul {
    margin-bottom: 1em;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.primaryLight2};
        border-radius: 50%;
        position: absolute;
        top: 0.875rem;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`
